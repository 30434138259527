import React, { useState } from 'react';
import { Route, Routes, Navigate, useParams } from 'react-router-dom';
import Layout from './Layout';
import JobListingsPage from './JobListingsPage';
import AddJobPage from './AddJobPage';
import SignInPage from './SignInPage';
import AccountsPage from './AccountsPage';
import ProtectedRoute from './ProtectedRoute';
import OrganizationsPage from './OrganizationsPage';
import ApplyJobPage from './ApplyJobPage';
import JobApplicationPage from './JobApplicationPage';
import ApplicationCompletePage from './ApplicationCompletePage';
import PaymentsPage from './PaymentsPage';
import NewUserWelcome from './NewUserWelcome';
import CandidateDashboard from './CandidateDashboard';
import { useAuth } from './AuthContext';
import TermsOfService from './TermsOfService.js';
import PrivacyPolicy from './PrivacyPolicy';
import JobSelectionPage from './JobSelectionPage';


// This component will handle the conditional rendering for the apply route
const ApplyRoute = () => {
  const { jobId } = useParams();
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return <ApplyJobPage />;
  } else {
    return <Navigate to={`/apply/new_user/${jobId}`} replace />;
  }
};

function AppContent() {
  const { userRole } = useAuth();
  const [selectedCandidateId, setSelectedCandidateId] = useState(null);
  const [show, setShow] = useState(true);
  const handleCloseCandidateDetails = () => {
    console.log('Closing candidate details');
    setSelectedCandidateId(0);
    setShow(false);
  };
  return (
    <Routes>
      <Route path="/terms-of-service" element={<TermsOfService />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/signin" element={<SignInPage />} />
      <Route path="/apply/new_user/:jobId" element={<NewUserWelcome />} />
      <Route path="/application-complete" element={<ApplicationCompletePage />} />
      <Route path="/apply/:jobId" element={<ApplyRoute />} />
      <Route element={<ProtectedRoute />}>
        {userRole === 'candidate' ? (
          <Route index element={<CandidateDashboard />} />
        ) : (
          <>
            <Route element={<Layout selectedCandidateId={selectedCandidateId} handleCloseCandidateDetails={handleCloseCandidateDetails} />}>
              <Route index element={<JobSelectionPage />} />
              <Route path="/job/:jobId" element={<JobListingsPage show={show} setShow={setShow} />} />
              <Route path="/payments" element={<PaymentsPage />} />
              <Route path="/add-job" element={<AddJobPage />} />
              <Route path="/organizations" element={<OrganizationsPage />} />
              <Route path="/account" element={<AccountsPage />} />
              <Route path="/application/:jobId" element={<JobApplicationPage />} />
            </Route>
          </>
        )}
      </Route>
    </Routes>
  );
}

export default AppContent;