import React, { useState, useEffect, useRef } from 'react';
import { useMediaQuery } from '@mui/material';
import { Typography, Button, Grid, FormControl, InputLabel, Select, MenuItem, LinearProgress, Box, Alert, Paper, Fade, Container, CircularProgress, Fab } from '@mui/material';
import { styled, keyframes } from '@mui/system';
import MicIcon from '@mui/icons-material/Mic';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import RefreshIcon from '@mui/icons-material/Refresh';
import StatusBubble from './StatusBubble';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CallEndIcon from '@mui/icons-material/CallEnd';

const GreenBox = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
  border: `2px solid ${theme.palette.primary.main}`,
}));

const LissajousCurve = styled('svg')(({ theme }) => ({
  width: '100%',
  height: 'auto',
  maxWidth: 240,
  maxHeight: 240,
  filter: 'drop-shadow(0 0 10px #4a8f99)',
}));

const expandAnimation = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.5);
    opacity: 0.7;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
`;

const AnimatedLissajousCurve = styled(LissajousCurve)(({ animate }) => ({
  animation: animate ? `${expandAnimation} 1s ease-out forwards` : 'none',
}));

const InterviewPrepStep = ({
  onStartInterview,
  isCallActive,
  callStatus,
  transcript,
  onEndCall,
  onBack,
  isDisconnected
}) => {
  const [audioDevices, setAudioDevices] = useState({ inputs: [], outputs: [] });
  const [selectedMicrophone, setSelectedMicrophone] = useState('');
  const [selectedSpeaker, setSelectedSpeaker] = useState('');
  const [audioLevel, setAudioLevel] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isTesting, setIsTesting] = useState(false);
  const audioContextRef = useRef(null);
  const analyserRef = useRef(null);
  const microphoneRef = useRef(null);
  const animationFrameRef = useRef(null);

  // Define browser checks at the component level
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
  const canSelectAudioOutput = !isSafari && !isFirefox;

  const [phase, setPhase] = useState(0);
  const [animate, setAnimate] = useState(false);

  const [isTransitioning, setIsTransitioning] = useState(false);
  const [localIsCallActive, setLocalIsCallActive] = useState(isCallActive);

  const [isPlutoSpeaking, setIsPlutoSpeaking] = useState(false);

  const transcriptRef = useRef(null);

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const [isFullScreen, setIsFullScreen] = useState(false);
  const isMobile = useMediaQuery('(max-width:600px)');

  // Add this new state to track if devices are available
  const [areDevicesAvailable, setAreDevicesAvailable] = useState(false);

  const handleEndCallClick = () => {
    setOpenConfirmDialog(true);
  };

  const handleConfirmEndCall = () => {
    setOpenConfirmDialog(false);
    onEndCall();
  };

  const handleCancelEndCall = () => {
    setOpenConfirmDialog(false);
  };

  useEffect(() => {
    if (transcriptRef.current) {
      transcriptRef.current.scrollTop = transcriptRef.current.scrollHeight;
    }
  }, [transcript]);

  useEffect(() => {
    if (callStatus === "Agent is speaking") {
      setIsPlutoSpeaking(true);
    } else if (callStatus === "Agent finished speaking") {
      setIsPlutoSpeaking(false);
    }
  }, [callStatus]);

  useEffect(() => {
    let animationFrame;
    let startTime;

    const animate = (timestamp) => {
      if (!startTime) startTime = timestamp;
      const progress = (timestamp - startTime) / 20000; // 20 seconds duration
      const newPhase = (progress % 1) * 2 * Math.PI;
      setPhase(newPhase);

      animationFrame = requestAnimationFrame(animate);
    };

    animationFrame = requestAnimationFrame(animate);

    return () => {
      cancelAnimationFrame(animationFrame);
    };
  }, []);

  const generateLissajousPath = (a, b, A, B, phase) => {
    const points = [];
    for (let t = 0; t <= 2 * Math.PI; t += 0.01) {
      const x = A * Math.sin(a * t + phase) + A;
      const y = B * Math.sin(b * t) + B;
      points.push(`${x},${y}`);
    }
    return `M ${points.join(' L ')}`;
  };

  useEffect(() => {
    const getAudioDevices = async () => {
      try {
        setIsLoading(true);
        await navigator.mediaDevices.getUserMedia({ audio: true });
        const devices = await navigator.mediaDevices.enumerateDevices();
        const audioInputs = devices.filter(device => device.kind === 'audioinput');
        const audioOutputs = devices.filter(device => device.kind === 'audiooutput');

        setAudioDevices({
          inputs: audioInputs,
          outputs: canSelectAudioOutput ? audioOutputs : [{ deviceId: 'default', label: 'Default' }]
        });

        // Check if both input and output devices are available
        setAreDevicesAvailable(audioInputs.length > 0 && audioOutputs.length > 0);

        if (audioInputs.length > 0) {
          setSelectedMicrophone(audioInputs[0].deviceId);
        }

        if (!canSelectAudioOutput) {
          setSelectedSpeaker('default');
        } else if (audioOutputs.length > 0) {
          setSelectedSpeaker(audioOutputs[0].deviceId);
        }
      } catch (err) {
        console.error('Error accessing audio devices:', err);
        setAreDevicesAvailable(false);
      } finally {
        setIsLoading(false);
      }
    };

    getAudioDevices();
  }, [canSelectAudioOutput]);

  const handleMicrophoneChange = (event) => {
    setSelectedMicrophone(event.target.value);
  };

  const handleSpeakerChange = (event) => {
    setSelectedSpeaker(event.target.value);
  };

  const startAudioTest = async () => {
    if (isTesting) {
      stopAudioTest();
      return;
    }

    setIsTesting(true);
    try {
      audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
      analyserRef.current = audioContextRef.current.createAnalyser();
      analyserRef.current.fftSize = 256;

      const stream = await navigator.mediaDevices.getUserMedia({
        audio: { deviceId: selectedMicrophone ? { exact: selectedMicrophone } : undefined }
      });

      microphoneRef.current = audioContextRef.current.createMediaStreamSource(stream);
      microphoneRef.current.connect(analyserRef.current);

      const bufferLength = analyserRef.current.frequencyBinCount;
      const dataArray = new Uint8Array(bufferLength);

      const updateAudioLevel = () => {
        analyserRef.current.getByteFrequencyData(dataArray);
        const average = dataArray.reduce((acc, value) => acc + value, 0) / bufferLength;
        setAudioLevel(Math.min((average / 128) * 100, 100));
        animationFrameRef.current = requestAnimationFrame(updateAudioLevel);
      };

      updateAudioLevel();
    } catch (error) {
      console.error('Error accessing microphone:', error);
      setIsTesting(false);
    }
  };

  const stopAudioTest = () => {
    if (animationFrameRef.current) {
      cancelAnimationFrame(animationFrameRef.current);
      animationFrameRef.current = null;
    }

    if (microphoneRef.current) {
      microphoneRef.current.disconnect();
      microphoneRef.current = null;
    }

    if (audioContextRef.current && audioContextRef.current.state !== 'closed') {
      audioContextRef.current.close().then(() => {
        audioContextRef.current = null;
      }).catch(err => console.error('Error closing AudioContext:', err));
    }

    setAudioLevel(0);
    setIsTesting(false);
  };

  useEffect(() => {
    return () => {
      stopAudioTest();
    };
  }, []);

  const testSpeaker = () => {
    const audio = new Audio('/test-audio.mp3');
    if (selectedSpeaker && canSelectAudioOutput) {
      audio.setSinkId(selectedSpeaker)
        .then(() => {
          console.log(`Audio is being played on device: ${selectedSpeaker}`);
          audio.play();
        })
        .catch(error => {
          console.error('Error setting audio output device:', error);
        });
    } else {
      audio.play();
    }
  };

  useEffect(() => {
    if (isCallActive && !localIsCallActive) {
      setIsTransitioning(false);
      setLocalIsCallActive(true);
    }
  }, [isCallActive, localIsCallActive]);

  const handleStartCall = (selectedMicrophone) => {
    setIsTransitioning(true);
    setAnimate(true);
    setTimeout(() => {
      onStartInterview(selectedMicrophone);
      if (isMobile) {
        setIsFullScreen(true);
      }
    }, 1000); // Match this with the animation duration
  };

  const refreshAudioDevices = async () => {
    setIsLoading(true);
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true });
      const devices = await navigator.mediaDevices.enumerateDevices();
      const audioInputs = devices.filter(device => device.kind === 'audioinput');
      const audioOutputs = devices.filter(device => device.kind === 'audiooutput');

      setAudioDevices({
        inputs: audioInputs,
        outputs: canSelectAudioOutput ? audioOutputs : [{ deviceId: 'default', label: 'Default' }]
      });

      // Preserve selected devices if they still exist, otherwise select the first available device
      setSelectedMicrophone(prevMic =>
        audioInputs.some(device => device.deviceId === prevMic) ? prevMic : (audioInputs[0]?.deviceId || '')
      );

      if (canSelectAudioOutput) {
        setSelectedSpeaker(prevSpeaker =>
          audioOutputs.some(device => device.deviceId === prevSpeaker) ? prevSpeaker : (audioOutputs[0]?.deviceId || '')
        );
      }
    } catch (err) {
      console.error('Error refreshing audio devices:', err);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <CircularProgress />
        <Typography variant="body1" sx={{ ml: 2 }}>Loading audio devices...</Typography>
      </Box>
    );
  }

  return (
    <Container maxWidth="lg" disableGutters sx={{
      height: isFullScreen && isMobile ? '100vh' : 'auto',
      width: isFullScreen && isMobile ? '100vw' : 'auto',
      position: isFullScreen && isMobile ? 'fixed' : 'static',
      top: isFullScreen && isMobile ? 0 : 'auto',
      left: isFullScreen && isMobile ? 0 : 'auto',
      zIndex: isFullScreen && isMobile ? 1300 : 'auto',
      padding: isFullScreen && isMobile ? 0 : undefined,
    }}>
      <Grid container spacing={2} sx={{ height: '100%' }}>
        <Grid item xs={12} sx={{ height: isFullScreen && isMobile ? '100%' : 'auto' }}>
          <GreenBox sx={{
            minHeight: isFullScreen && isMobile ? '100vh' : { xs: 'auto', sm: '40vh' },
            height: isFullScreen && isMobile ? '100%' : 'auto',
            position: 'relative',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            p: { xs: 2, sm: 3 },
            pb: localIsCallActive ? 7 : { xs: 2, sm: 3 }, // Add extra padding at the bottom when call is active
          }}>
            <Fade in={!localIsCallActive} timeout={500}>
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%'
              }}>
                <Typography variant="h5" align="center" sx={{ mb: 2, color: '#4a8f99', fontSize: { xs: '1.25rem', sm: '1.5rem' } }}>
                  Hi, I'm Pluto
                </Typography>
                <Box sx={{
                  width: '100%',
                  maxWidth: { xs: 180, sm: 240 },
                  aspectRatio: '1/1',
                  position: 'relative',
                  mb: 2
                }}>
                  <AnimatedLissajousCurve animate={animate} viewBox="0 0 240 240">
                    <path
                      d={generateLissajousPath(10, 12, 120, 120, phase)}
                      fill="none"
                      stroke="#4a8f99"
                      strokeWidth="2"
                    />
                  </AnimatedLissajousCurve>
                </Box>
                <Typography variant="h5" align="center" sx={{ mb: 2, fontSize: { xs: '1.25rem', sm: '1.5rem' } }}>
                  <p>Begin Conversation</p>
                </Typography>
                <Typography variant="body2" align="center" sx={{ mb: 2, fontSize: { xs: '0.875rem', sm: '1rem' }, color: 'text.secondary' }}>
                  This call will be recorded and shared with the hiring manager.
                </Typography>
                {!isTransitioning && (
                  <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                    <Button
                      onClick={onBack}
                      variant="outlined"
                      sx={{
                        color: '#4a8f99',
                        borderColor: '#4a8f99',
                        '&:hover': { borderColor: '#3b7b84' },
                        mr: 2,
                        fontSize: { xs: '0.8rem', sm: '0.875rem' }
                      }}
                    >
                      Back
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => handleStartCall(selectedMicrophone)}
                      disabled={!areDevicesAvailable} // Disable if devices are not available
                      sx={{
                        bgcolor: '#4a8f99',
                        '&:hover': { bgcolor: '#3b7b84' },
                        '&.Mui-disabled': {
                          bgcolor: 'rgba(74, 143, 153, 0.5)',
                          color: 'rgba(255, 255, 255, 0.7)',
                        },
                        fontSize: { xs: '0.8rem', sm: '0.875rem' }
                      }}
                    >
                      Start Call
                    </Button>
                  </Box>
                )}
                {!areDevicesAvailable && (
                  <Typography variant="body2" color="error" align="center" sx={{ mt: 2 }}>
                    No microphone or speaker detected. Please check your audio devices and refresh.
                  </Typography>
                )}
              </Box>
            </Fade>
            <Fade in={localIsCallActive} timeout={500}>
              <Box sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: localIsCallActive ? 'flex' : 'none',
                flexDirection: 'column',
                p: 2
              }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                  <Typography variant="h6" sx={{ fontSize: { xs: '1rem', sm: '1.25rem' } }}>Call Status: {callStatus}</Typography>
                  <StatusBubble isSpeaking={isPlutoSpeaking} />
                </Box>
                {isDisconnected && (
                  <Alert severity="warning" sx={{ mb: 2 }}>
                    Connection lost. Attempting to reconnect...
                  </Alert>
                )}
                <Box
                  ref={transcriptRef}
                  sx={{
                    width: '100%',
                    flexGrow: 1,
                    overflowY: 'auto',
                    mb: 2,
                    scrollBehavior: 'smooth'
                  }}
                >
                  {transcript.map((entry, index) => (
                    <Typography
                      key={index}
                      variant="body1"
                      sx={{
                        mb: 1,
                        textAlign: 'left',
                        fontSize: { xs: '0.9rem', sm: '1.1rem' },
                        display: 'flex',
                        alignItems: 'flex-start',
                        '& strong': {
                          minWidth: { xs: '40px', sm: '50px' },
                          marginRight: '8px',
                        }
                      }}
                    >
                      <strong>{entry.role === 'agent' ? 'Pluto:' : 'You:'}</strong>
                      <span>{entry.content}</span>
                    </Typography>
                  ))}
                </Box>
                {/* Add the End Call button here */}
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleEndCallClick}
                    sx={{
                      fontSize: { xs: '0.8rem', sm: '0.875rem' },
                      minWidth: 120,
                    }}
                  >
                    End Call
                  </Button>
                </Box>
              </Box>
            </Fade>
          </GreenBox>
        </Grid>
        {(!isFullScreen || !isMobile) && (
          <>
            <Grid item xs={12} sm={6}>
              <GreenBox sx={{ p: { xs: 2, sm: 3 }, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <Typography variant="h6" align="center" sx={{ fontSize: { xs: '1rem', sm: '1.25rem' }, mb: 2 }}>
                  {localIsCallActive ? "Interview in progress" : "Are you ready?"}
                </Typography>
                {!localIsCallActive && (
                  <Typography variant="body1" align="left" sx={{ fontSize: { xs: '0.9rem', sm: '1rem' }, flexGrow: 1 }}>
                    We've told Pluto about you and the job you're applying for, they're ready to ask some questions regarding your experiences. If you're not ready, you can come back at any time before pressing Start Call.
                  </Typography>
                )}
              </GreenBox>
            </Grid>
            <Grid item xs={12} sm={6}>
              <GreenBox sx={{ p: { xs: 2, sm: 3 }, height: '100%', display: 'flex', flexDirection: 'column' }}>
                <Typography variant="h6" align="center" sx={{ mb: 2, fontSize: { xs: '1rem', sm: '1.25rem' } }}>
                  Let's check your setup
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
                  <Button
                    variant="outlined"
                    startIcon={<RefreshIcon />}
                    onClick={refreshAudioDevices}
                    disabled={localIsCallActive}
                    sx={{ fontSize: { xs: '0.8rem', sm: '0.875rem' } }}
                  >
                    Refresh Devices
                  </Button>
                </Box>
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <InputLabel>Microphone</InputLabel>
                  <Select
                    value={selectedMicrophone}
                    onChange={handleMicrophoneChange}
                    label="Microphone"
                    disabled={localIsCallActive}
                  >
                    {audioDevices.inputs.length > 0 ? (
                      audioDevices.inputs.map((device) => (
                        <MenuItem key={device.deviceId} value={device.deviceId}>
                          {device.label || `Microphone ${device.deviceId.slice(0, 5)}`}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value="">No microphones found</MenuItem>
                    )}
                  </Select>
                </FormControl>
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <InputLabel>Speaker</InputLabel>
                  <Select
                    value={selectedSpeaker}
                    onChange={handleSpeakerChange}
                    label="Speaker"
                    disabled={localIsCallActive || !canSelectAudioOutput}
                  >
                    {audioDevices.outputs.length > 0 ? (
                      audioDevices.outputs.map((device) => (
                        <MenuItem key={device.deviceId} value={device.deviceId}>
                          {device.label || `Speaker ${device.deviceId.slice(0, 5)}`}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value="">No speakers found</MenuItem>
                    )}
                  </Select>
                </FormControl>
                <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, alignItems: 'center', width: '100%', mb: 1 }}>
                  <Button
                    variant="outlined"
                    startIcon={<MicIcon />}
                    onClick={startAudioTest}
                    sx={{ mr: { xs: 0, sm: 2 }, mb: { xs: 1, sm: 0 }, fontSize: { xs: '0.8rem', sm: '0.875rem' } }}
                    disabled={localIsCallActive || audioDevices.inputs.length === 0}
                  >
                    {isTesting ? 'Stop Test' : 'Test Microphone'}
                  </Button>
                  <Button
                    variant="outlined"
                    startIcon={<PlayArrowIcon />}
                    onClick={testSpeaker}
                    sx={{ mr: { xs: 0, sm: 2 }, mb: { xs: 1, sm: 0 }, fontSize: { xs: '0.8rem', sm: '0.875rem' } }}
                    disabled={localIsCallActive || audioDevices.outputs.length === 0}
                  >
                    Test Speaker
                  </Button>
                  <LinearProgress
                    variant="determinate"
                    value={audioLevel}
                    sx={{
                      flexGrow: 1,
                      height: 10,
                      borderRadius: 5,
                      backgroundColor: (theme) => theme.palette.grey[300],
                      '& .MuiLinearProgress-bar': {
                        backgroundColor: '#4a8f99',
                      },
                    }}
                  />
                </Box>
              </GreenBox>
            </Grid>
          </>
        )}
      </Grid>

      {isFullScreen && isMobile && localIsCallActive && (
        <Fab
          color="secondary"
          aria-label="end call"
          onClick={handleEndCallClick}
          sx={{
            position: 'fixed',
            bottom: 16,
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: 1400,
          }}
        >
          <CallEndIcon />
        </Fab>
      )}

      <Dialog
        open={openConfirmDialog}
        onClose={handleCancelEndCall}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"End the call?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to end the call? This call will be recorded and shared with the hiring manager.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelEndCall} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmEndCall} color="secondary" autoFocus>
            End Call
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default InterviewPrepStep;