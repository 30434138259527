import React from 'react';
import { Box, Container, Typography } from '@mui/material';

const ApplicationCompletePage = () => {
  return (
    <Container maxWidth="md">
      <Box sx={{
        mt: 4,
        mb: 4,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh'
      }}>
        <Typography variant="h2" align="left" gutterBottom>
          Application Complete
        </Typography>
        <Typography variant="h6" align="left" gutterBottom>
          You have successfully submitted your application. Again, if you have any questions or concerns please email us at founders@hirepluto.com
        </Typography>
        <iframe
          src="https://tally.so/embed/w4vDPA?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1"
          width="100%"
          height="500"
          frameBorder="0"
          marginHeight="0"
          marginWidth="0"
          title="Application Feedback Form"
        />
      </Box>
    </Container>
  );
};

export default ApplicationCompletePage;