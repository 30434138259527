import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  Typography, Paper, CircularProgress, Box, List, ListItem, ListItemText,
  Button, Grid, Accordion, AccordionSummary, AccordionDetails, Card, CardContent,
  Slider, Snackbar, Alert, Select, MenuItem, FormControl, OutlinedInput, Divider,
  Dialog, DialogTitle, DialogContent, DialogActions
} from '@mui/material';
import { Email, GetApp, LinkedIn, ExpandMore, PlayArrow, Pause, Speed, QuestionAnswer, CheckCircle, Cancel, PictureAsPdf } from '@mui/icons-material';
import TranscriptDisplay from './TranscriptDisplay';
import config from './config';
import axios from 'axios';
import debounce from 'lodash/debounce';
import { jsPDF } from "jspdf";
import 'jspdf-autotable';

const CandidateDetailsComponent = ({ candidate, jobId, onStageChange, onClose }) => {
  console.log("CandidateDetailsComponent props:", { candidate, jobId });

  const [candidateDetails, setCandidateDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [playbackError, setPlaybackError] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const audioRef = useRef(null);
  const [stage, setStage] = useState('');
  const [notes, setNotes] = useState('');
  const [applicationId, setApplicationId] = useState(false);
  const [playbackRate, setPlaybackRate] = useState(1);
  const [openNoResumeDialog, setOpenNoResumeDialog] = useState(false);
  const [saveStatus, setSaveStatus] = useState('');

  const handleTimeUpdate = useCallback(() => {
    if (audioRef.current) {
      setCurrentTime(audioRef.current.currentTime);
    }
  }, []);

  useEffect(() => {
    if (candidate && candidate.id) {
      console.log("CandidateDetailsComponent mounted with ID:", candidate.id);
      const fetchCandidateDetails = async () => {
        setLoading(true);
        setIsPlaying(false); // Reset playing state
        setPlaybackRate(1); // Reset playback rate to 1x
        if (audioRef.current) {
          audioRef.current.pause(); // Pause any playing audio
          audioRef.current.currentTime = 0; // Reset audio time
          audioRef.current.playbackRate = 1; // Reset audio playback rate
        }
        try {
          const id = typeof candidate.id === 'object' && candidate.id !== null ? candidate.id.id : candidate.id;
          console.log('Fetching details for candidate:', id);
          const response = await axios.get(`${config.API_URL}/jobs/candidates/${id}`, {
            headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
          });
          console.log('Fetched candidate details:', response.data);
          setCandidateDetails(response.data);
          setNotes(response.data?.notes || '')
          setApplicationId(response.data?.id)
          setStage(response.data?.stage || 'Pending');
          setCurrentTime(0); // Reset current time
          setDuration(0); // Reset duration

          if (response.data.audio_file_url) {
            const audio = new Audio(response.data.audio_file_url);
            audio.addEventListener('loadedmetadata', () => {
              setDuration(audio.duration);
            });
            audioRef.current = audio;
          }
        } catch (err) {
          console.error('Error fetching candidate details:', err.response ? err.response.data : err.message);
          setError(err.response ? err.response.data.detail : err.message);
        } finally {
          setLoading(false);
        }
      };

      fetchCandidateDetails();
    } else {
      console.error("Invalid candidate prop:", candidate);
    }

    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.removeEventListener('timeupdate', handleTimeUpdate);
      }
    };
  }, [candidate, handleTimeUpdate]);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.addEventListener('timeupdate', handleTimeUpdate);
    }
    return () => {
      if (audioRef.current) {
        audioRef.current.removeEventListener('timeupdate', handleTimeUpdate);
      }
    };
  }, [handleTimeUpdate]);

  useEffect(() => {
    console.log('Current stage:', stage);
  }, [stage]);

  // eslint-disable-next-line
  const saveNotes = useCallback(
    debounce(async (notesToSave) => {
      if (!applicationId) return;

      setSaveStatus('Saving...');
      try {
        await axios.patch(
          `${config.API_URL}/jobs/candidates/${applicationId}/notes`,
          { notes: notesToSave },
          {
            headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
          }
        );
        setSaveStatus('Saved');
        setTimeout(() => setSaveStatus(''), 2000); // Clear status after 2 seconds
      } catch (error) {
        console.error("Error updating notes:", error);
        setSaveStatus('Error saving');
        setTimeout(() => setSaveStatus(''), 2000);
      }
    }, 500),
    [applicationId]
  );

  const handleNotesChange = (event) => {
    const newNotes = event.target.value;
    setNotes(newNotes);
    saveNotes(newNotes);
  };

  const handlePlayPause = async () => {
    try {
      if (audioRef.current.paused) {
        await audioRef.current.play();
        setIsPlaying(true);
      } else {
        audioRef.current.pause();
        setIsPlaying(false);
      }
    } catch (err) {
      console.error('Error during audio playback:', err);
      setSnackbarMessage('Unable to play audio. The media resource might be unavailable.');
      setPlaybackError(true);
      setSnackbarOpen(true);
      setIsPlaying(false);
    }
  };

  const handleSliderChange = (event, newValue) => {
    if (audioRef.current) {
      audioRef.current.currentTime = newValue;
      setCurrentTime(newValue);
    }
  };

  const handleSendEmail = () => {
    if (candidateDetails.email) {
      window.location.href = `mailto:${candidateDetails.email}`;
    }
  };

  const handleDownloadResume = async () => {
    try {
      const response = await fetch(`${config.API_URL}/jobs/resume/${candidateDetails.id}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (response.status === 404) {
        setOpenNoResumeDialog(true);
        return;
      }

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // Get the filename from the Content-Disposition header
      const contentDisposition = response.headers.get('Content-Disposition');
      const filenameMatch = contentDisposition && contentDisposition.match(/filename="?(.+)"?/i);
      const filename = filenameMatch ? filenameMatch[1] : 'resume.pdf';

      // Create a blob from the response
      const blob = await response.blob();

      // Create a temporary URL for the blob
      const url = window.URL.createObjectURL(blob);

      // Create a temporary anchor element and trigger the download
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();

      // Clean up
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error downloading resume:', error);
      setSnackbarMessage('Error downloading resume');
      setSnackbarOpen(true);
    }
  };

  const handleCloseNoResumeDialog = () => {
    setOpenNoResumeDialog(false);
  };

  const handlePreviewLinkedIn = () => {
    if (candidateDetails.linkedin_link) {
      window.location.href = candidateDetails.linkedin_link;
    }
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  const handlePlaybackRateChange = (event) => {
    const newValue = parseFloat(event.target.value);
    setPlaybackRate(newValue);
    if (audioRef.current) {
      audioRef.current.playbackRate = newValue;
    }
  };

  const handleDownloadAudio = () => {
    if (candidateDetails.audio_file_url) {
      window.open(candidateDetails.audio_file_url, '_blank');
    }
  };

  const renderInterviewResponse = (responseData) => {
    const { question_type, response } = responseData;

    const commonStyles = {
      display: 'flex',
      alignItems: 'flex-start',
      mb: 1,
    };

    const iconStyle = {
      mr: 1,
      mt: 0.5,
    };

    switch (question_type) {
      case 'shortAnswer':
        return (
          <Box>
            <Box sx={commonStyles}>
              <QuestionAnswer sx={iconStyle} color="primary" />
              <Typography variant="body1"><strong>Answer:</strong> {response.eval_reasoning}</Typography>
            </Box>
            <Box sx={commonStyles}>
              <Speed sx={iconStyle} color="secondary" />
              <Typography variant="body1"><strong>Score:</strong> {response.eval_score}/5</Typography>
            </Box>
          </Box>
        );
      case 'boolean':
        return (
          <Box>
            <Box sx={commonStyles}>
              {response.answer ? (
                <CheckCircle sx={iconStyle} color="success" />
              ) : (
                <Cancel sx={iconStyle} color="error" />
              )}
              <Typography variant="body1"><strong>Answer:</strong> {response.answer ? 'Yes' : 'No'}</Typography>
            </Box>
            <Box sx={commonStyles}>
              <QuestionAnswer sx={iconStyle} color="primary" />
              <Typography variant="body1"><strong>Reasoning:</strong> {response.reasoning}</Typography>
            </Box>
          </Box>
        );
      case 'number':
        return (
          <Box>
            <Box sx={commonStyles}>
              <Speed sx={iconStyle} color="primary" />
              <Typography variant="body1"><strong>Answer:</strong> {response.answer}</Typography>
            </Box>
            <Box sx={commonStyles}>
              <QuestionAnswer sx={iconStyle} color="secondary" />
              <Typography variant="body1"><strong>Reasoning:</strong> {response.reasoning}</Typography>
            </Box>
          </Box>
        );
      default:
        return <Typography>Unknown question type</Typography>;
    }
  };

  const handleStageChange = async (candidateId, newStage) => {
    try {
      // Call the parent component's onStageChange function
      await onStageChange(newStage);

      // Update the local state
      setCandidateDetails(prevCandidate => ({
        ...prevCandidate,
        stage: newStage
      }));

      // Optionally, you can show a success message
      setSnackbarMessage('Stage updated successfully');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error updating stage:', error);
      // Optionally, you can show an error message
      setSnackbarMessage('Failed to update stage');
      setSnackbarOpen(true);
    }
  };

  const handleDownloadPDF = () => {
    const doc = new jsPDF();

    // Helper function to add text with word wrap and page breaks
    const addWrappedText = (text, x, y, maxWidth, lineHeight) => {
      const lines = doc.splitTextToSize(text, maxWidth);
      lines.forEach((line, index) => {
        if (y > 280) {
          doc.addPage();
          y = 20;
        }
        doc.text(line, x, y);
        y += lineHeight;
      });
      return y;
    };

    // Helper function to check and add a new page if needed
    const checkNewPage = (y) => {
      if (y > 280) {
        doc.addPage();
        return 20;
      }
      return y;
    };

    // Set font
    doc.setFont("helvetica", "bold");

    // Add candidate name and email
    doc.setFontSize(20);
    doc.text(`${candidateDetails.first_name} ${candidateDetails.last_name}`, 20, 20);
    doc.setFontSize(12);
    doc.text(`Email: ${candidateDetails.email}`, 20, 30);

    // Table of Contents
    doc.setFontSize(16);
    doc.text("Table of Contents", 20, 45);
    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");
    let tocY = 55;
    const sections = [
      "Interview Responses",
      "Strengths and Weaknesses",
      "Cultural Fit",
      "Sentiment",
      "Delivery of Speech",
      "Candidate Questions",
      "Top Skills",
      "Cohort"
    ];
    sections.forEach((section, index) => {
      doc.setTextColor(0, 0, 255);  // Set text color to blue for links
      doc.textWithLink(`${index + 1}. ${section}`, 25, tocY, { pageNumber: index + 2 });  // Link to the corresponding page
      doc.setTextColor(0);  // Reset text color to black
      tocY += 10;
    });

    // Content pages
    sections.forEach((section, index) => {
      doc.addPage();
      doc.setFontSize(16);
      doc.setFont("helvetica", "bold");
      doc.text(`${index + 1}. ${section}`, 20, 20);
      let yPos = 40;

      switch (index) {
        case 0:  // Interview Responses
          if (candidateDetails.interview_responses && Object.keys(candidateDetails.interview_responses).length > 0) {
            Object.entries(candidateDetails.interview_responses).forEach(([question, responseData]) => {
              doc.setFontSize(12);
              doc.setFont("helvetica", "bold");
              yPos = addWrappedText(question, 20, yPos, 170, 7);
              yPos += 5;

              doc.setFont("helvetica", "normal");
              if (responseData.question_type === 'shortAnswer') {
                yPos = addWrappedText(`Answer: ${responseData.response.eval_reasoning}`, 30, yPos, 160, 7);
                yPos = addWrappedText(`Score: ${responseData.response.eval_score}/5`, 30, yPos, 160, 7);
              } else if (responseData.question_type === 'boolean') {
                yPos = addWrappedText(`Answer: ${responseData.response.answer ? 'Yes' : 'No'}`, 30, yPos, 160, 7);
                yPos = addWrappedText(`Reasoning: ${responseData.response.reasoning}`, 30, yPos, 160, 7);
              } else if (responseData.question_type === 'number') {
                yPos = addWrappedText(`Answer: ${responseData.response.answer}`, 30, yPos, 160, 7);
                yPos = addWrappedText(`Reasoning: ${responseData.response.reasoning}`, 30, yPos, 160, 7);
              }

              yPos += 10;
              yPos = checkNewPage(yPos);
            });
          } else {
            doc.setFontSize(12);
            doc.text("No interview responses available", 20, yPos);
          }
          break;
        case 1:  // Strengths and Weaknesses
          doc.setFontSize(12);
          doc.setFont("helvetica", "normal");
          doc.text("Strengths:", 20, yPos);
          yPos += 10;
          candidateDetails.strengths?.forEach(strength => {
            yPos = addWrappedText(`• ${strength}`, 30, yPos, 160, 7);
          });

          yPos = checkNewPage(yPos + 10);
          doc.text("Weaknesses:", 20, yPos);
          yPos += 10;
          candidateDetails.weaknesses?.forEach(weakness => {
            yPos = addWrappedText(`• ${weakness}`, 30, yPos, 160, 7);
          });
          break;
        case 2:  // Cultural Fit
          doc.setFontSize(12);
          doc.setFont("helvetica", "normal");
          yPos = addWrappedText(candidateDetails.cultural_fit_reasoning, 20, yPos, 170, 7);
          break;
        case 3:  // Sentiment
          doc.setFontSize(12);
          doc.setFont("helvetica", "normal");
          yPos = addWrappedText(candidateDetails.sentiment, 20, yPos, 170, 7);
          break;
        case 4:  // Delivery of Speech
          doc.setFontSize(12);
          doc.setFont("helvetica", "normal");
          yPos = addWrappedText(candidateDetails.speech_skills, 20, yPos, 170, 7);
          break;
        case 5:  // Candidate Questions
          doc.setFontSize(12);
          doc.setFont("helvetica", "normal");
          if (candidateDetails.candidate_questions && candidateDetails.candidate_questions.length > 0) {
            candidateDetails.candidate_questions.forEach((question, idx) => {
              yPos = addWrappedText(`${idx + 1}. ${question}`, 20, yPos, 170, 7);
              yPos += 5;
              yPos = checkNewPage(yPos);
            });
          } else {
            doc.text("Candidate asked no questions", 20, yPos);
          }
          break;
        case 6:  // Top Skills
          doc.setFontSize(12);
          doc.setFont("helvetica", "normal");
          if (candidateDetails.top_skills && candidateDetails.top_skills.length > 0) {
            candidateDetails.top_skills.forEach((skillObj, idx) => {
              yPos = addWrappedText(`${idx + 1}. ${skillObj.skill}`, 20, yPos, 170, 7);
              yPos = addWrappedText(`   Reasoning: ${skillObj.reasoning}`, 20, yPos, 170, 7);
              yPos += 5;
              yPos = checkNewPage(yPos);
            });
          } else {
            doc.text("No skills available", 20, yPos);
          }
          break;
        case 7:  // Cohort
          doc.setFontSize(12);
          doc.setFont("helvetica", "normal");
          const cohort = candidateDetails.ranking === 0 ? 'C' :
            candidateDetails.ranking === 1 ? 'B' :
              candidateDetails.ranking === 2 ? 'A' : 'N/A';
          doc.text(`Cohort: ${cohort}`, 20, yPos);
          break;
        default:
          doc.setFontSize(12);
          doc.setFont("helvetica", "normal");
          doc.text(`No specific content for section: ${section}`, 20, yPos);
          break;
      }
    });

    // Save the PDF
    doc.save(`${candidateDetails.first_name}_${candidateDetails.last_name}_details.pdf`);
  };

  if (loading) return <CircularProgress />;
  if (error) return <Typography variant="h6" color="error">{error}</Typography>;
  if (!candidateDetails) return <Typography>No candidate data available</Typography>;

  console.log('Rendering CandidateDetailsComponent with candidate:', candidateDetails);

  return (
    <Box sx={{ position: 'relative', minHeight: '100vh' }}>
      <Dialog open={openNoResumeDialog} onClose={handleCloseNoResumeDialog}>
        <DialogTitle>No Resume Found</DialogTitle>
        <DialogContent>
          <Typography>
            No resume was found for this candidate. They may not have uploaded one during the application process.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseNoResumeDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }} >
        <Typography variant="h4">{candidateDetails.first_name} {candidateDetails.last_name}</Typography>
        <FormControl variant="outlined" sx={{ minWidth: 120 }}>
          <Select
            value={candidateDetails?.stage || ''}
            onChange={(e) => handleStageChange(candidateDetails.id, e.target.value)}
            label="Stage"
            input={<OutlinedInput label="Stage" />}
            sx={{
              '.MuiSelect-select': {
                display: 'flex',
                alignItems: 'center',
                padding: '6px 12px',
                borderRadius: '16px',
                color: '#4a8f99',
                border: '1px solid #4a8f99',
              },
              '.MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
            }}
          >
            <MenuItem value="Pending">Pending</MenuItem>
            <MenuItem value="Inbox">Inbox</MenuItem>
            <MenuItem value="Interview">Interview</MenuItem>
            <MenuItem value="Offer">Offer</MenuItem>
            <MenuItem value="Archive">Archive</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box sx={{ mb: 2 }}>
        <Button startIcon={<Email />} variant="outlined" sx={{ mr: 1 }} onClick={handleSendEmail}>
          Send Email
        </Button>
        <Button startIcon={<GetApp />} variant="outlined" sx={{ mr: 1 }} onClick={handleDownloadResume}>
          Download Resume
        </Button>
        <Button startIcon={<LinkedIn />} variant="outlined" sx={{ mr: 1 }} onClick={handlePreviewLinkedIn}>
          Preview LinkedIn
        </Button>
        <Button startIcon={<PictureAsPdf />} variant="outlined" onClick={handleDownloadPDF}>
          Download Details as PDF
        </Button>
      </Box>

      {candidateDetails.audio_file_url && (
        <Paper sx={{ p: 2, mb: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <Button onClick={handlePlayPause}>
              {isPlaying ? <Pause /> : <PlayArrow />}
            </Button>
            <Slider
              sx={{ mx: 2, flexGrow: 1 }}
              value={currentTime}
              max={duration}
              onChange={handleSliderChange}
            />
            <Typography sx={{ minWidth: '60px', textAlign: 'right' }}>
              {formatTime(currentTime)} / {formatTime(duration)}
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>
              <Speed sx={{ mr: 1 }} />
              <Select
                value={playbackRate}
                onChange={handlePlaybackRateChange}
                sx={{ mr: 2, height: '36px' }}
                size="small"
              >
                <MenuItem value={0.5}>0.5x</MenuItem>
                <MenuItem value={1}>1x</MenuItem>
                <MenuItem value={1.5}>1.5x</MenuItem>
                <MenuItem value={2}>2x</MenuItem>
              </Select>
            </Box>
            <Button variant="outlined" onClick={handleDownloadAudio} size="small">
              Download Audio
            </Button>
          </Box>
        </Paper>
      )}

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography>View Transcript</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TranscriptDisplay transcript={candidateDetails.audio_transcript || []} />
        </AccordionDetails>
      </Accordion>

      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12} md={8}>
          <Paper sx={{ p: 2, mb: 2 }}>
            <Typography variant="h6" gutterBottom>Interview Responses</Typography>
            {candidateDetails.interview_responses && Object.keys(candidateDetails.interview_responses).length > 0 ? (
              Object.entries(candidateDetails.interview_responses).map(([question, responseData], index) => (
                <Box key={index} sx={{ mb: 3 }}>
                  <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold', color: 'primary.main' }}>
                    {question}
                  </Typography>
                  {renderInterviewResponse(responseData)}
                  {index < Object.keys(candidateDetails.interview_responses).length - 1 && (
                    <Divider sx={{ mt: 2, mb: 2 }} />
                  )}
                </Box>
              ))
            ) : (
              <Typography>No interview responses available</Typography>
            )}
          </Paper>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Paper sx={{ p: 2, height: '100%' }}>
                <Typography variant="h6" gutterBottom>Candidate Strengths</Typography>
                <ul>
                  {candidateDetails.strengths?.map((strength, index) => (
                    <li key={index}>{strength}</li>
                  ))}
                </ul>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper sx={{ p: 2, height: '100%' }}>
                <Typography variant="h6" gutterBottom>Candidate Weaknesses</Typography>
                <ul>
                  {candidateDetails.weaknesses?.map((weakness, index) => (
                    <li key={index}>{weakness}</li>
                  ))}
                </ul>
              </Paper>
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} sm={6}>
              <Paper sx={{ p: 2, height: '100%' }}>
                <Typography variant="h6" gutterBottom>Candidate Sentiment</Typography>
                <Typography>{candidateDetails.sentiment}</Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper sx={{ p: 2, height: '100%' }}>
                <Typography variant="h6" gutterBottom>Cultural Fit</Typography>
                <Typography>{candidateDetails.cultural_fit_reasoning}</Typography>
              </Paper>
            </Grid>
          </Grid>

          <Paper sx={{ p: 2, mt: 2 }}>
            <Typography variant="h6" gutterBottom>Delivery of Speech</Typography>
            <Typography>{candidateDetails.speech_skills}</Typography>
          </Paper>

          <Paper sx={{ p: 2, mt: 2 }}>
            <Typography variant="h6" gutterBottom>Candidate Questions</Typography>
            {candidateDetails.candidate_questions && candidateDetails.candidate_questions.length > 0 ? (
              <List>
                {candidateDetails.candidate_questions.map((question, index) => (
                  <ListItem key={index}>
                    <ListItemText primary={`${index + 1}. ${question}`} />
                  </ListItem>
                ))}
              </List>
            ) : (
              <Typography>Candidate asked no questions</Typography>
            )}
          </Paper>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>Notes</Typography>
              <textarea
                value={notes}
                onChange={handleNotesChange}
                rows={5}
                style={{
                  width: '27rem',
                  height: '8rem',
                  border: '1px solid #D9D9D9',
                  padding: '0.5rem',
                  borderRadius: '0.375rem',
                  resize: 'none'
                }}
              />
              {saveStatus && (
                <Typography variant="caption" color={saveStatus === 'Error saving' ? 'error' : 'textSecondary'}>
                  {saveStatus}
                </Typography>
              )}
            </CardContent>
          </Card>

          <Paper sx={{ p: 2, mt: 2 }}>
            <Typography variant="h6" gutterBottom>Top Skills</Typography>
            <List>
              {candidateDetails.top_skills && candidateDetails.top_skills.length > 0 ? (
                candidateDetails.top_skills.map((skillObj, index) => (
                  <ListItem key={index}>
                    <ListItemText
                      primary={`${index + 1}. ${skillObj.skill}`}
                      secondary={skillObj.reasoning}
                    />
                  </ListItem>
                ))
              ) : (
                <ListItem>
                  <ListItemText primary="No skills available" />
                </ListItem>
              )}
            </List>
          </Paper>

          <Paper sx={{ p: 2, mt: 2 }}>
            <Typography variant="h6" gutterBottom>Candidate Details</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography><strong>Cohort:</strong> {
                  candidateDetails.ranking === 0 ? 'C' :
                    candidateDetails.ranking === 1 ? 'B' :
                      candidateDetails.ranking === 2 ? 'A' : 'N/A'
                }</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity={playbackError ? 'error' : 'info'}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default CandidateDetailsComponent;