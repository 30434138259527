import React, { useState } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  Box,
  Button,
  CssBaseline,
  ThemeProvider,
  useMediaQuery,
} from '@mui/material';
import {
  AccountCircle,
  ExitToApp,
  Business,
  Dashboard,
  Menu as MenuIcon,
} from '@mui/icons-material';
import { useAuth } from './AuthContext';
import theme from './theme';

const Layout = ({ handleCloseCandidateDetails }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const location = useLocation();
  const { logout } = useAuth();

  const handleLogout = () => {
    localStorage.removeItem('token');
    logout();
    navigate('/signin');
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const menuItems = [
    { text: 'Job Listings', icon: <Dashboard />, path: '/', clicked: handleCloseCandidateDetails },
    { text: 'Account', icon: <AccountCircle />, path: '/account' },
    { text: 'Organizations', icon: <Business />, path: '/organizations' },
  ];

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh', bgcolor: 'background.default' }}>
        <AppBar position="static">
          <Toolbar>
            <img
              src="/logo.png"
              alt="Retell AI Logo"
              style={{ width: '50px', height: 'auto', marginRight: '16px' }}
            />
            {isMobile ? (
              <>
                <IconButton
                  color="inherit"
                  aria-label="menu"
                  onClick={handleMenuOpen}
                  edge="start"
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                  {menuItems.map((item) => (
                    <MenuItem
                      key={item.text}
                      onClick={() => {
                        navigate(item.path);
                        handleMenuClose();
                        item.clicked && item.clicked();
                      }}
                    >
                      {item.text}
                    </MenuItem>
                  ))}
                  <MenuItem onClick={handleLogout}>Log out</MenuItem>
                </Menu>
              </>
            ) : (
              <>
                {menuItems.map((item) => (
                  <Button
                    key={item.text}
                    color="inherit"
                    startIcon={item.icon}
                    onClick={() => {
                      navigate(item.path);
                      item.clicked && item.clicked();
                    }}
                    sx={{
                      mx: 1,
                      color: item.path === location.pathname ? theme.palette.secondary.main : 'inherit',
                    }}
                  >
                    {item.text}
                  </Button>
                ))}
                <Box sx={{ flexGrow: 1 }} />
                <IconButton color="inherit" onClick={handleLogout}>
                  <ExitToApp />
                </IconButton>
              </>
            )}
          </Toolbar>
        </AppBar>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 2, // Reduced padding from 3 to 2
            pt: 1, // Added top padding of 1
            overflowY: 'auto',
            bgcolor: 'background.default',
            height: 'calc(100vh - 64px)', // Subtract AppBar height
          }}
        >
          <Outlet />
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default Layout;