import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Box,
  Typography,
  Button,
  Paper,
  CircularProgress,
  useTheme,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  IconButton,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Snackbar,
  Alert,
  TableSortLabel,
  InputLabel,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import config from "./config";
import CandidateDetailsComponent from "./CandidateDetailsComponent";
import PropTypes from 'prop-types';
import FilterListIcon from '@mui/icons-material/FilterList';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import CloseIcon from '@mui/icons-material/Close';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import { styled } from '@mui/material/styles';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";

const createApi = () =>
  axios.create({
    baseURL: config.API_URL,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  color: theme.palette.common.black,
  fontWeight: 'bold',
}));

const JobListingsPage = ({ show, setShow }) => {
  const { jobId } = useParams();
  const [job, setJob] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState("");
  const navigate = useNavigate();
  const theme = useTheme();

  const [filters, setFilters] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [candidates, setCandidates] = useState([]);
  const [, setOriginalCandidates] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [openCloseJobDialog, setOpenCloseJobDialog] = useState(false);
  const [jobCloseDate, setJobCloseDate] = useState(null);
  const [showFilters, setShowFilters] = useState(true);
  const [showJobDetails, setShowJobDetails] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [candidateDialogOpen, setCandidateDialogOpen] = useState(false);

  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("Application Date");

  const [cohortFilter, setCohortFilter] = useState('');
  const [stageFilter, setStageFilter] = useState('');

  const [customFilters, setCustomFilters] = useState({});
  const [activeSheet, setActiveSheet] = useState('None');
  const [sheetDialogOpen, setSheetDialogOpen] = useState(false);
  const [newSheetName, setNewSheetName] = useState('');
  const [unsavedChanges, setUnsavedChanges] = useState({});

  const [manageFiltersOpen, setManageFiltersOpen] = useState(false);
  const [, setEditingFilterName] = useState(null);
  const [newFilterName, setNewFilterName] = useState('');

  const sortedItems = useMemo(() => {
    return [...candidates]
      .filter((candidate) => {
        const matchesCohort = !cohortFilter ||
          (candidate.ranking === 2 && cohortFilter === 'A') ||
          (candidate.ranking === 1 && cohortFilter === 'B') ||
          (candidate.ranking === 0 && cohortFilter === 'C');
        const matchesStage = !stageFilter || candidate.stage === stageFilter;
        const matchesSearch = candidate.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          candidate.last_name.toLowerCase().includes(searchTerm.toLowerCase());

        // Check if any filters are applied
        const hasAppliedFilters = Object.values(filters).some(value => 
          value !== '' && value !== null && value !== undefined && 
          (typeof value === 'object' ? (value.operator && value.value) : true)
        );

        // If no filters are applied, don't filter based on interview responses
        if (!hasAppliedFilters) {
          return matchesCohort && matchesStage && matchesSearch;
        }

        // Apply interview question filters only if filters are applied
        const matchesFilters = Object.entries(filters).every(([question, filterValue]) => {
          // If no filter is applied for this question, return true
          if (!filterValue) return true;

          const response = candidate.interview_responses?.[question];
          
          // If there's no response and a filter is applied, treat it as not matching
          if (!response) return false;

          const answer = response.response?.answer;
          console.log(`Candidate: ${candidate.first_name}, Question: ${question}, Answer: ${answer}, Filter: ${filterValue}`);

          if (response.question_type === "boolean") {
            const booleanAnswer = answer === true || answer === "true" || answer === "True";
            const booleanFilter = filterValue === true || filterValue === "true" || filterValue === "True";
            return booleanAnswer === booleanFilter;
          } else if (response.question_type === "shortAnswer") {
            const score = response.response?.eval_score;
            const minScore = Number(filterValue);
            return score >= minScore;
          } else if (response.question_type === "number") {
            const { operator, value } = filterValue;
            if (!value) return true; // No value set
            const numAnswer = Number(answer);
            const numValue = Number(value);
            switch (operator) {
              case 'gt': return numAnswer > numValue;
              case 'lt': return numAnswer < numValue;
              case 'eq': return numAnswer === numValue;
              default: return true;
            }
          } else {
            const normalizedAnswer = String(answer).toLowerCase();
            const normalizedFilterValue = String(filterValue).toLowerCase();
            return normalizedAnswer === normalizedFilterValue;
          }
        });

        return matchesCohort && matchesStage && matchesSearch && matchesFilters;
      })
      .sort((a, b) => {
        if (orderBy === "name") {
          return order === "asc"
            ? `${a.first_name || ""} ${a.last_name || ""}`.localeCompare(
              `${b.first_name || ""} ${b.last_name || ""}`
            )
            : `${b.first_name || ""} ${b.last_name || ""}`.localeCompare(
              `${a.first_name || ""} ${a.last_name || ""}`
            );
        }
        if (orderBy === "email") {
          return order === "asc"
            ? (a.email || "").localeCompare(b.email || "")
            : (b.email || "").localeCompare(a.email || "");
        }
        if (orderBy === "status") {
          const statusA = a.status || "";
          const statusB = b.status || "";
          return order === "asc"
            ? statusA.localeCompare(statusB)
            : statusB.localeCompare(statusA);
        }
        if (orderBy === "Application Date") {
          return order === "asc"
            ? new Date(a.created_at) - new Date(b.created_at)
            : new Date(b.created_at) - new Date(a.created_at);
        }
        if (orderBy === "stage") {
          const stageA = a.stage || "";
          const stageB = b.stage || "";
          return order === "asc"
            ? stageA.localeCompare(stageB)
            : stageB.localeCompare(stageA);
        }
        if (orderBy === "Cohort") {
          return order === "asc"
            ? (a?.ranking ?? -1) - (b?.ranking ?? -1)
            : (b?.ranking ?? -1) - (a?.ranking ?? -1);
        }
        return 0;
      });
  }, [candidates, order, orderBy, searchTerm, cohortFilter, stageFilter, filters]);

  const apiRef = useRef(createApi());

  const fetchJobDetails = useCallback(async () => {
    try {
      const [jobResponse, candidatesResponse, closeDateResponse] =
        await Promise.all([
          apiRef.current.get(`/jobs/${jobId}`),
          apiRef.current.get(`/jobs/${jobId}/candidates`),
          apiRef.current.get(`/jobs/${jobId}/close-date`),
        ]);

      const jobData = jobResponse.data;
      setJob(jobData);
      setCandidates(candidatesResponse.data);
      setOriginalCandidates(candidatesResponse.data);

      if (closeDateResponse.data && closeDateResponse.data.close_date) {
        setJobCloseDate(dayjs(closeDateResponse.data.close_date));
      } else {
        setJobCloseDate(null);
      }

      // Initialize filters based on interview_questions
      const initialFilters = {};
      jobData.interview_questions.forEach((question) => {
        initialFilters[question.question] =
          question.question_type === "number"
            ? { operator: "gt", value: "" }
            : "";
      });
      setFilters(initialFilters);

      // Set custom filters and apply the first sheet if available
      if (jobData.custom_filters && jobData.custom_filters.custom_filters) {
        setCustomFilters(jobData.custom_filters.custom_filters);
      } else {
        setCustomFilters({});
      }
      // Always set to 'None' initially
      setActiveSheet('None');
      setFilters({});
      setCohortFilter('');
      setStageFilter('');
      setSearchTerm('');
    } catch (err) {
      setError(err.response?.data?.detail || "Failed to fetch job details");
    } finally {
      setLoading(false);
    }
  }, [jobId]);

  const checkForChanges = useCallback(() => {
    if (activeSheet === 'None') return; // Don't check for changes on 'None' sheet

    const currentSheet = customFilters[activeSheet] || {};
    const originalFilters = currentSheet.filters || {};
    const originalCohortFilter = currentSheet.cohortFilter || '';
    const originalStageFilter = currentSheet.stageFilter || '';
    const originalSearchTerm = currentSheet.searchTerm || '';

    const filtersChanged = JSON.stringify(filters) !== JSON.stringify(originalFilters);
    const cohortChanged = cohortFilter !== originalCohortFilter;
    const stageChanged = stageFilter !== originalStageFilter;
    const searchChanged = searchTerm !== originalSearchTerm;
    
    const hasChanges = filtersChanged || cohortChanged || stageChanged || searchChanged;

    setUnsavedChanges(prev => ({
      ...prev,
      [activeSheet]: hasChanges,
    }));
  }, [activeSheet, customFilters, filters, cohortFilter, stageFilter, searchTerm]);

  useEffect(() => {
    checkForChanges();
  }, [filters, cohortFilter, stageFilter, searchTerm, checkForChanges]);

  const handleEditJob = () => {
    navigate("/add-job", { state: { editJob: job } });
  };

  const handleDeleteClick = (event) => {
    event.stopPropagation();
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (deleteConfirmation.toLowerCase() === "yes") {
      try {
        await apiRef.current.delete(`/jobs/${job.id}`);
        setJob(null);
      } catch (err) {
        setError(err.response?.data?.detail || "Failed to delete job");
      }
    }
    setDeleteDialogOpen(false);
    setDeleteConfirmation("");
  };

  const truncateDescription = (description, maxLength = 100) => {
    if (description.length <= maxLength) return description;
    return `${description.substring(0, maxLength)}...`;
  };

  const handleFilterChange = (questionKey, value) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [questionKey]: value,
    }));
  };

  const handleCohortFilterChange = (event) => {
    setCohortFilter(event.target.value);
  };

  const handleStageFilterChange = (event) => {
    setStageFilter(event.target.value);
  };

  const clearFilters = () => {
    setCohortFilter('');
    setStageFilter('');
    setSearchTerm('');
    setFilters({});
  };

  const handleCopyApplyUrl = () => {
    const applyUrl = `${config.WEBSITE_URL}/apply/${job.external_id}`;
    navigator.clipboard
      .writeText(applyUrl)
      .then(() => {
        alert("Apply URL copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  const handleNextCandidate = useCallback(() => {
    if (sortedItems.length === 0) return;
    const currentIndex = sortedItems.findIndex(c => c.id === selectedCandidate?.id);
    const nextIndex = (currentIndex + 1) % sortedItems.length;
    setSelectedCandidate(sortedItems[nextIndex]);
  }, [sortedItems, selectedCandidate]);

  const handlePreviousCandidate = useCallback(() => {
    if (sortedItems.length === 0) return;
    const currentIndex = sortedItems.findIndex(c => c.id === selectedCandidate?.id);
    const previousIndex = (currentIndex - 1 + sortedItems.length) % sortedItems.length;
    setSelectedCandidate(sortedItems[previousIndex]);
  }, [sortedItems, selectedCandidate]);

  const handleStageChange = useCallback(
    async (candidateId, newStage) => {
      console.log("handleStageChange called with:", { candidateId, newStage });
      try {
        if (!candidateId) {
          console.error('Invalid candidate ID:', candidateId);
          setSnackbarMessage('Failed to update candidate stage: Invalid ID');
          setSnackbarOpen(true);
          return;
        }

        // Ensure newStage is a string
        const stageValue = String(newStage);

        console.log("Sending PATCH request with payload:", { stage: stageValue });
        const response = await apiRef.current.patch(
          `/jobs/candidates/${candidateId}/stage`,
          { stage: stageValue }
        );
        console.log("PATCH request response:", response);

        setCandidates((prevCandidates) =>
          prevCandidates.map((candidate) =>
            candidate.id === candidateId ? { ...candidate, stage: stageValue } : candidate
          )
        );

        setSnackbarMessage("Candidate stage updated successfully");
        setSnackbarOpen(true);
      } catch (error) {
        console.error("Failed to update stage:", error);
        setSnackbarMessage("Failed to update candidate stage");
        setSnackbarOpen(true);
      }
    },
    [setSnackbarMessage, setSnackbarOpen]
  );

  const handleCandidateClick = useCallback((event, candidateId) => {
    event.preventDefault();
    event.stopPropagation();
    console.log("Candidate clicked:", candidateId);
    const candidate = candidates.find(c => c.id === candidateId);
    console.log("Found candidate:", candidate);
    if (candidate && candidate.id) {
      setSelectedCandidate(candidate);
      setCandidateDialogOpen(true);
      console.log("Setting selected candidate:", candidate);
    } else {
      console.error("Invalid candidate:", candidate);
      setSnackbarMessage("Failed to load candidate details");
      setSnackbarOpen(true);
    }

  }, [candidates, setSnackbarMessage, setSnackbarOpen]);

  const handleCloseCandidateDialog = useCallback(() => {
    setCandidateDialogOpen(false);
    setSelectedCandidate(null);
  }, []);

  const handleCloseJobClick = () => {
    setOpenCloseJobDialog(true);
  };

  const handleCloseJobDialogClose = () => {
    setOpenCloseJobDialog(false);
  };

  const handleCloseJobSubmit = async () => {
    try {
      const response = await apiRef.current.put(
        `/jobs/${job.id}/close-date`,
        {
          close_date: jobCloseDate.toISOString(),
        }
      );

      if (response.status === 200) {
        setOpenCloseJobDialog(false);
        setSnackbarMessage("Job close date updated successfully");
        setSnackbarOpen(true);
      }
    } catch (err) {
      console.error("Error closing job:", err);
      setSnackbarMessage("Failed to update job close date");
      setSnackbarOpen(true);
    }
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleExportToCsv = () => {
    // Generate CSV content
    const headers = ['Last Name', 'First Name', 'Email', ...job.interview_questions.map(q => q.question), 'Cohort'];
    const csvContent = [
      headers.map(header => `"${header.replace(/"/g, '""')}"`).join(','),
      ...sortedItems.map(candidate => {
        const row = [
          candidate.last_name || '',
          candidate.first_name || '',
          candidate.email || '',
          ...job.interview_questions.map(question => {
            const response = candidate.interview_responses?.[question.question];
            if (!response) return '';

            if (question.question_type === "boolean") {
              return response.response?.answer ? 'TRUE' : 'FALSE';
            } else if (question.question_type === "shortAnswer") {
              const answer = response.response?.answer || '';
              const score = response.response?.eval_score || '';
              const reasoning = response.response?.eval_reasoning || '';
              return `${answer} ${reasoning} (Score: ${score})`;
            } else if (question.question_type === "number") {
              return response.response?.answer || '';
            } else {
              return response.response?.answer || '';
            }
          }),
          candidate.ranking === 2 ? 'A' : candidate.ranking === 1 ? 'B' : candidate.ranking === 0 ? 'C' : ''
        ];
        return row.map(cell => `"${cell.toString().replace(/"/g, '""')}"`).join(',');
      })
    ].join('\n');

    // Create and download the CSV file
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', `${job.title}_applicants.csv`);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const handleSaveCustomFilters = async () => {
    if (activeSheet === 'None') return; // Don't save 'None' filter

    try {
      const updatedCustomFilters = {
        ...customFilters,
        [activeSheet]: {
          filters,
          cohortFilter,
          stageFilter,
          searchTerm,
        },
      };
      await apiRef.current.put(`/jobs/${jobId}/custom-filters`, {
        custom_filters: updatedCustomFilters,
      });
      setCustomFilters(updatedCustomFilters);
      setUnsavedChanges(prev => ({
        ...prev,
        [activeSheet]: false,
      }));
      setSnackbarMessage("Custom filters saved successfully");
      setSnackbarOpen(true);
    } catch (err) {
      console.error("Error saving custom filters:", err);
      setSnackbarMessage("Failed to save custom filters");
      setSnackbarOpen(true);
    }
  };

  const handleAddNewFilter = async () => {
    if (newFilterName && !customFilters[newFilterName]) {
      try {
        const updatedCustomFilters = {
          ...customFilters,
          [newFilterName]: {
            filters: {},
            cohortFilter: '',
            stageFilter: '',
            searchTerm: '',
          }
        };

        // Save the new filter immediately
        await apiRef.current.put(`/jobs/${jobId}/custom-filters`, {
          custom_filters: updatedCustomFilters,
        });

        setCustomFilters(updatedCustomFilters);
        setNewFilterName('');
        setSnackbarMessage("New filter created successfully");
        setSnackbarOpen(true);
      } catch (err) {
        console.error("Error creating new filter:", err);
        setSnackbarMessage("Failed to create new filter");
        setSnackbarOpen(true);
      }
    } else if (customFilters[newFilterName]) {
      setSnackbarMessage("Filter name already exists");
      setSnackbarOpen(true);
    } else {
      setSnackbarMessage("Please enter a filter name");
      setSnackbarOpen(true);
    }
  };

  const handleCreateNewSheet = async () => {
    if (newSheetName) {
      try {
        // Save current sheet's unsaved changes
        if (activeSheet !== 'None') {
          const currentSheetFilters = {
            filters,
            cohortFilter,
            stageFilter,
            searchTerm,
          };
          setUnsavedChanges(prev => ({
            ...prev,
            [activeSheet]: currentSheetFilters,
          }));
        }

        const newSheet = {
          filters: {},
          cohortFilter: '',
          stageFilter: '',
          searchTerm: '',
        };

        const updatedCustomFilters = {
          ...customFilters,
          [newSheetName]: newSheet,
        };

        // Save the new sheet immediately
        await apiRef.current.put(`/jobs/${jobId}/custom-filters`, {
          custom_filters: updatedCustomFilters,
        });

        setCustomFilters(updatedCustomFilters);
        setActiveSheet(newSheetName);
        setFilters({});
        setCohortFilter('');
        setStageFilter('');
        setSearchTerm('');
        setNewSheetName('');
        setSheetDialogOpen(false);
        setSnackbarMessage("New filter created successfully");
        setSnackbarOpen(true);
      } catch (err) {
        console.error("Error creating new filter:", err);
        setSnackbarMessage("Failed to create new filter");
        setSnackbarOpen(true);
      }
    }
  };

  const handleSelectSheet = useCallback((sheetName) => {
    if (sheetName === activeSheet) return; // Don't do anything if selecting the same sheet

    // Save current sheet's unsaved changes
    if (activeSheet !== 'None') {
      const currentSheetFilters = {
        filters,
        cohortFilter,
        stageFilter,
        searchTerm,
      };

      // Only update unsaved changes if there are actual changes
      if (JSON.stringify(currentSheetFilters) !== JSON.stringify(customFilters[activeSheet])) {
        setUnsavedChanges(prev => ({
          ...prev,
          [activeSheet]: currentSheetFilters,
        }));
      }
    }

    setActiveSheet(sheetName);
    if (sheetName === 'None') {
      setFilters({});
      setCohortFilter('');
      setStageFilter('');
      setSearchTerm('');
    } else {
      const sheetFilters = unsavedChanges[sheetName] || customFilters[sheetName] || {};
      setFilters(sheetFilters.filters || {});
      setCohortFilter(sheetFilters.cohortFilter || '');
      setStageFilter(sheetFilters.stageFilter || '');
      setSearchTerm(sheetFilters.searchTerm || '');
    }
  }, [activeSheet, filters, cohortFilter, stageFilter, searchTerm, unsavedChanges, customFilters]);

  const handleOpenManageFilters = () => {
    setManageFiltersOpen(true);
  };

  const handleCloseManageFilters = () => {
    setManageFiltersOpen(false);
    setEditingFilterName(null);
    setNewFilterName('');
  };

  const handleEditFilterName = (filterName) => {
    setEditingFilterName(filterName);
  };

  const handleDeleteFilter = (filterName) => {
    const updatedFilters = { ...customFilters };
    delete updatedFilters[filterName];
    setCustomFilters(updatedFilters);
    if (activeSheet === filterName) {
      setActiveSheet('None');
    }
  };

  const onDragEnd = useCallback((result) => {
    const { source, destination } = result;

    if (!destination) return;

    setCustomFilters(prevFilters => {
      const items = Array.from(Object.keys(prevFilters));
      const [reorderedItem] = items.splice(source.index, 1);
      items.splice(destination.index, 0, reorderedItem);

      const reorderedFilters = {};
      items.forEach(item => {
        reorderedFilters[item] = prevFilters[item];
      });

      return reorderedFilters;
    });
  }, []);

  const handleSaveFilterChanges = async () => {
    try {
      await apiRef.current.put(`/jobs/${jobId}/custom-filters`, {
        custom_filters: customFilters,
      });
      setSnackbarMessage("Filter changes saved successfully");
      setSnackbarOpen(true);
      handleCloseManageFilters();
    } catch (err) {
      console.error("Error saving filter changes:", err);
      setSnackbarMessage("Failed to save filter changes");
      setSnackbarOpen(true);
    }
  };

  useEffect(() => {
    fetchJobDetails();
  }, [fetchJobDetails]);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Typography variant="h6" color="error">
        {error}
      </Typography>
    );
  }

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  return (
    <Box sx={{ display: 'flex', height: '100%', overflow: 'hidden' }}>
      <Box sx={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        mr: 2,
        height: '100%',
        overflow: 'hidden'
      }}>
        {job ? (
          <>
            {/* Job details paper */}
            <Paper elevation={1} sx={{ p: 2, borderRadius: 2, flexShrink: 0 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
                <Typography
                  variant="h4"
                  sx={{ color: theme.palette.primary.main, display: 'flex', alignItems: 'center' }}
                >
                  {job.title}
                  <IconButton
                    onClick={() => setShowJobDetails(!showJobDetails)}
                    sx={{ ml: 1, p: 0 }}
                  >
                    {showJobDetails ?
                      <ExpandLessIcon sx={{ fontSize: '1.5rem' }} /> :
                      <ExpandMoreIcon sx={{ fontSize: '1.5rem' }} />
                    }
                  </IconButton>
                </Typography>
                <Box>
                  <Tooltip title="Set Close Date">
                    <IconButton onClick={handleCloseJobClick} sx={{ mr: 1 }}>
                      <AccessTimeIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Edit Job">
                    <IconButton
                      onClick={handleEditJob}
                      sx={{ mr: 1 }}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete Job">
                    <IconButton
                      onClick={handleDeleteClick}
                      sx={{
                        "&:hover": {
                          color: "error.main",
                        },
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
              {showJobDetails && (
                <>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                        Company:
                      </Typography>
                      <Typography variant="body1">
                        {job.company_name || "N/A"}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                        Organization:
                      </Typography>
                      <Typography variant="body1">
                        {job.organization?.name || "N/A"}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography
                    variant="body1"
                    sx={{ mt: 2, mb: 1, fontWeight: "bold" }}
                  >
                    Description:
                  </Typography>
                  <Typography variant="body2">
                    {truncateDescription(job.description)}
                  </Typography>
                </>
              )}
            </Paper>

            {/* Search bar and filters */}
            <Paper elevation={1} sx={{ p: 2, borderRadius: 2, flexShrink: 0 }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <TextField
                  id="outlined-basic"
                  label="Search Candidate name"
                  variant="outlined"
                  onChange={(e) => setSearchTerm(e.target.value)}
                  value={searchTerm}
                  size="small"
                  sx={{ flexGrow: 1, mr: 1 }}
                />
                <Box>
                  <Tooltip title="Clear Filters">
                    <IconButton onClick={clearFilters} sx={{ mr: 1 }}>
                      <FilterAltOffIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={showFilters ? "Hide Filters" : "Show Filters"}>
                    <IconButton onClick={toggleFilters}>
                      <FilterListIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                <FormControl size="small" sx={{ minWidth: 120 }}>
                  <InputLabel id="cohort-filter-label">Cohort</InputLabel>
                  <Select
                    labelId="cohort-filter-label"
                    id="cohort-filter"
                    value={cohortFilter}
                    label="Cohort"
                    onChange={handleCohortFilterChange}
                    startAdornment={<FilterAltIcon fontSize="small" sx={{ mr: 1 }} />}
                  >
                    <MenuItem value="">All</MenuItem>
                    <MenuItem value="A">A</MenuItem>
                    <MenuItem value="B">B</MenuItem>
                    <MenuItem value="C">C</MenuItem>
                  </Select>
                </FormControl>
                <FormControl size="small" sx={{ minWidth: 120 }}>
                  <InputLabel id="stage-filter-label">Stage</InputLabel>
                  <Select
                    labelId="stage-filter-label"
                    id="stage-filter"
                    value={stageFilter}
                    label="Stage"
                    onChange={handleStageFilterChange}
                    startAdornment={<FilterAltIcon fontSize="small" sx={{ mr: 1 }} />}
                  >
                    <MenuItem value="">All</MenuItem>
                    <MenuItem value="Pending">Pending</MenuItem>
                    <MenuItem value="Inbox">Inbox</MenuItem>
                    <MenuItem value="Offer">Offer</MenuItem>
                    <MenuItem value="Interview">Interview</MenuItem>
                    <MenuItem value="Archive">Archive</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Paper>

            {/* Applicants paper */}
            <Paper elevation={1} sx={{
              p: 2,
              borderRadius: 2,
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              overflow: 'hidden'
            }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2, flexShrink: 0 }}>
                <Typography variant="h6">Applicants</Typography>
                <Box>
                  <Tooltip title="Export to CSV">
                    <IconButton onClick={handleExportToCsv} sx={{ mr: 1 }}>
                      <FileDownloadIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Copy Apply Job URL">
                    <IconButton onClick={handleCopyApplyUrl}>
                      <ContentCopyIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
              <TableContainer sx={{ flexGrow: 1, overflow: 'auto' }}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>
                        <TableSortLabel
                          active={orderBy === "name"}
                          direction={orderBy === "name" ? order : "asc"}
                          onClick={() => handleRequestSort("name")}
                        >
                          Name
                        </TableSortLabel>
                      </StyledTableCell>
                      <StyledTableCell>
                        <TableSortLabel
                          active={orderBy === "email"}
                          direction={orderBy === "email" ? order : "asc"}
                          onClick={() => handleRequestSort("email")}
                        >
                          Email
                        </TableSortLabel>
                      </StyledTableCell>
                      <StyledTableCell>
                        <TableSortLabel
                          active={orderBy === "Application Date"}
                          direction={orderBy === "Application Date" ? order : "desc"}
                          onClick={() => handleRequestSort("Application Date")}
                        >
                          Application Date
                        </TableSortLabel>
                      </StyledTableCell>
                      <StyledTableCell>
                        <TableSortLabel
                          active={orderBy === "status"}
                          direction={orderBy === "status" ? order : "asc"}
                          onClick={() => handleRequestSort("status")}
                        >
                          Status
                        </TableSortLabel>
                      </StyledTableCell>
                      <StyledTableCell>
                        <TableSortLabel
                          active={orderBy === "stage"}
                          direction={orderBy === "stage" ? order : "asc"}
                          onClick={() => handleRequestSort("stage")}
                        >
                          Stage
                        </TableSortLabel>
                      </StyledTableCell>
                      <StyledTableCell>
                        <TableSortLabel
                          active={orderBy === "Cohort"}
                          direction={orderBy === "Cohort" ? order : "asc"}
                          onClick={() => handleRequestSort("Cohort")}
                        >
                          Cohort
                        </TableSortLabel>
                      </StyledTableCell>
                      <StyledTableCell>
                        Cheating Probability
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sortedItems.length > 0 ? (
                      sortedItems.map((candidate) => (
                        <TableRow
                          key={candidate.id}
                          sx={{
                            cursor: 'pointer',
                            '&:hover': {
                              backgroundColor: theme.palette.action.hover,
                            },
                          }}
                          onClick={(event) => handleCandidateClick(event, candidate.id)}
                        >
                          <TableCell>
                            {candidate.first_name} {candidate.last_name}
                          </TableCell>
                          <TableCell>{candidate.email}</TableCell>
                          <TableCell>
                            {candidate.created_at
                              ? new Date(
                                candidate.created_at
                              ).toLocaleString()
                              : "N/A"}
                          </TableCell>
                          <TableCell>{candidate.status}</TableCell>
                          <TableCell>
                            <FormControl variant="outlined" size="small">
                              <Select
                                value={candidate.stage || ""}
                                onChange={(e) =>
                                  handleStageChange(
                                    candidate.id,
                                    e.target.value
                                  )
                                }
                                onClick={(e) => e.stopPropagation()}
                                displayEmpty
                                renderValue={(value) =>
                                  value || "Select Stage"
                                }
                                IconComponent={ArrowDropDownIcon}
                                sx={{
                                  ".MuiSelect-select": {
                                    padding: "6px 12px",
                                    borderRadius: "16px",
                                    color: "#4a8f99",
                                    border: "1px solid #4a8f99",
                                  },
                                  ".MuiOutlinedInput-notchedOutline": {
                                    border: "none",
                                  },
                                }}
                              >
                                <MenuItem value="Pending">Pending</MenuItem>
                                <MenuItem value="Inbox">Inbox</MenuItem>
                                <MenuItem value="Interview">
                                  Interview
                                </MenuItem>
                                <MenuItem value="Offer">Offer</MenuItem>
                                <MenuItem value="Archive">Archive</MenuItem>
                              </Select>
                            </FormControl>
                          </TableCell>
                          <TableCell>
                            {candidate.ranking === 2
                              ? "A"
                              : candidate.ranking === 1
                                ? "B"
                                : candidate.ranking === 0
                                  ? "C"
                                  : ""}
                          </TableCell>
                          <TableCell>Low</TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={7} align="center">
                          No applicants yet
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </>
        ) : (
          <Typography variant="h6">Job not found</Typography>
        )}
      </Box>
      <Box sx={{ width: '300px', display: 'flex', flexDirection: 'column' }}>
        {/* Custom Filters paper */}
        <Paper elevation={1} sx={{ p: 2, borderRadius: 2, mb: 2 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Typography variant="h6">Custom Filters</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Tooltip title="Manage Filters">
                <IconButton onClick={handleOpenManageFilters} sx={{ mr: 1 }}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Save Custom Filters">
                <IconButton onClick={handleSaveCustomFilters} disabled={activeSheet === 'None'}>
                  <SaveIcon />
                </IconButton>
              </Tooltip>
              {Object.values(unsavedChanges).some(Boolean) && (
                <Tooltip title="Unsaved changes">
                  <FiberManualRecordIcon color="error" sx={{ fontSize: 12, ml: 1 }} />
                </Tooltip>
              )}
            </Box>
          </Box>
          <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
            <Button
              variant={activeSheet === 'None' ? "contained" : "outlined"}
              onClick={() => handleSelectSheet('None')}
              size="small"
            >
              None
            </Button>
            {Object.keys(customFilters).map((sheetName) => (
              <Button
                key={sheetName}
                variant={activeSheet === sheetName ? "contained" : "outlined"}
                onClick={() => handleSelectSheet(sheetName)}
                size="small"
              >
                {sheetName}
              </Button>
            ))}
          </Box>
        </Paper>
        {/* Filters paper */}
        <Paper elevation={1} sx={{
          p: 2,
          borderRadius: 2,
          flexGrow: 1,
          overflow: 'auto'
        }}>
          <Typography variant="h6" sx={{ mb: 2 }}>Filters</Typography>
          {job?.interview_questions.map((question) => (
            <Box key={question.question} sx={{ mb: 2 }}>
              <Typography variant="subtitle2" sx={{ mb: 1 }}>
                {question.question}
              </Typography>
              {question.question_type === "shortAnswer" ? (
                <FormControl fullWidth size="small">
                  <Select
                    value={filters[question.question] || ""}
                    onChange={(e) => handleFilterChange(question.question, e.target.value)}
                  >
                    <MenuItem value="">All Scores</MenuItem>
                    <MenuItem value="1">1 or higher (Poor+)</MenuItem>
                    <MenuItem value="2">2 or higher (Fair+)</MenuItem>
                    <MenuItem value="3">3 or higher (Good+)</MenuItem>
                    <MenuItem value="4">4 or higher (Very Good+)</MenuItem>
                    <MenuItem value="5">5 (Excellent)</MenuItem>
                  </Select>
                </FormControl>
              ) : question.question_type === "number" ? (
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <FormControl sx={{ width: "30%" }} size="small">
                    <Select
                      value={filters[question.question]?.operator || "gt"}
                      onChange={(e) =>
                        handleFilterChange(question.question, {
                          ...filters[question.question],
                          operator: e.target.value,
                        })
                      }
                    >
                      <MenuItem value="gt">&gt;</MenuItem>
                      <MenuItem value="lt">&lt;</MenuItem>
                      <MenuItem value="eq">=</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    fullWidth
                    type="number"
                    size="small"
                    value={filters[question.question]?.value || ""}
                    onChange={(e) =>
                      handleFilterChange(question.question, {
                        ...filters[question.question],
                        value: e.target.value,
                      })
                    }
                    sx={{ width: "70%" }}
                  />
                </Box>
              ) : question.question_type === "boolean" ? (
                <FormControl fullWidth size="small">
                  <Select
                    value={filters[question.question] || ""}
                    onChange={(e) => handleFilterChange(question.question, e.target.value)}
                  >
                    <MenuItem value="">All</MenuItem>
                    <MenuItem value="true">Yes</MenuItem>
                    <MenuItem value="false">No</MenuItem>
                  </Select>
                </FormControl>
              ) : (
                <TextField
                  fullWidth
                  size="small"
                  value={filters[question.question] || ""}
                  onChange={(e) => handleFilterChange(question.question, e.target.value)}
                />
              )}
            </Box>
          ))}
        </Paper>
      </Box>

      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {'Are you sure you want to delete this job? This action cannot be undone. Please type "yes" to confirm.'}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="confirmation"
            label="Type 'yes' to confirm"
            type="text"
            fullWidth
            variant="standard"
            value={deleteConfirmation}
            onChange={(e) => setDeleteConfirmation(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleDeleteConfirm} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity="info">
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <Dialog open={openCloseJobDialog} onClose={handleCloseJobDialogClose}>
        <DialogTitle>Set Job Close Date</DialogTitle>
        <DialogContent>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              label="Close Date"
              value={jobCloseDate}
              onChange={(newValue) => setJobCloseDate(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  sx={{
                    mt: 2,
                    "& .MuiInputLabel-root": {
                      transform: "translate(14px, -9px) scale(0.75)",
                      background: "#fff",
                      padding: "0 8px",
                    },
                  }}
                />
              )}
            />
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseJobDialogClose}>Cancel</Button>
          <Button
            onClick={handleCloseJobSubmit}
            variant="contained"
            color="primary"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={candidateDialogOpen}
        onClose={handleCloseCandidateDialog}
        fullWidth
        maxWidth="md"
        PaperProps={{
          sx: {
            height: '80vh',
            maxHeight: '80vh',
            display: 'flex',
            flexDirection: 'column',
          }
        }}
      >
        <DialogTitle>
          Candidate Details
          <Typography variant="subtitle1" sx={{ ml: 2, display: 'inline' }}>
            {sortedItems.length > 0 ? `${sortedItems.findIndex(c => c.id === selectedCandidate?.id) + 1}/${sortedItems.length}` : 'No candidates'}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleCloseCandidateDialog}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ display: 'flex', p: 0, flexGrow: 1, overflow: 'hidden' }}>
          <Box sx={{ flexGrow: 1, overflowY: 'auto', p: 3 }}>
            {selectedCandidate && (
              <CandidateDetailsComponent
                candidate={selectedCandidate}
                jobId={job?.id}
                onStageChange={(newStage) => {
                  console.log("onStageChange called in CandidateDetailsComponent:", { selectedCandidate, newStage });
                  if (selectedCandidate && selectedCandidate.id) {
                    handleStageChange(selectedCandidate.id, newStage);
                  } else {
                    console.error("Selected candidate or ID is undefined:", selectedCandidate);
                    setSnackbarMessage("Failed to update candidate: Invalid candidate");
                    setSnackbarOpen(true);
                  }
                }}
                onClose={handleCloseCandidateDialog}
              />
            )}
          </Box>
          <Box sx={{
            width: '120px',
            backgroundColor: (theme) => theme.palette.grey[100],
            borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            p: 1,
            ml: 2,
          }}>
            <Button
              onClick={handlePreviousCandidate}
              disabled={sortedItems.length <= 1}
              sx={{ mb: 2 }}
              variant="outlined"
            >
              Previous
            </Button>
            <Button
              onClick={handleNextCandidate}
              disabled={sortedItems.length <= 1}
              variant="outlined"
            >
              Next
            </Button>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog
        open={sheetDialogOpen}
        onClose={() => setSheetDialogOpen(false)}
      >
        <DialogTitle>Create New Sheet</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="sheet-name"
            label="Sheet Name"
            type="text"
            fullWidth
            variant="standard"
            value={newSheetName}
            onChange={(e) => setNewSheetName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSheetDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleCreateNewSheet}>Create</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={manageFiltersOpen}
        onClose={handleCloseManageFilters}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Manage Custom Filters</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', mb: 2 }}>
            <TextField
              value={newFilterName}
              onChange={(e) => setNewFilterName(e.target.value)}
              placeholder="New filter name"
              fullWidth
              size="small"
            />
            <Button
              onClick={handleAddNewFilter}
              startIcon={<AddIcon />}
              variant="contained"
              sx={{ ml: 1 }}
            >
              Add
            </Button>
          </Box>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="filter-list">
              {(provided) => (
                <div 
                  {...provided.droppableProps} 
                  ref={provided.innerRef}
                  style={{ maxHeight: '400px', overflowY: 'auto' }}
                >
                  {Object.keys(customFilters).map((filterName, index) => (
                    <Draggable key={filterName} draggableId={filterName} index={index}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                            userSelect: 'none',
                            padding: 16,
                            margin: '0 0 8px 0',
                            minHeight: '50px',
                            backgroundColor: '#f0f0f0',
                            ...provided.draggableProps.style
                          }}
                        >
                          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography>{filterName}</Typography>
                            <div>
                              <IconButton onClick={() => handleEditFilterName(filterName)}>
                                <EditIcon />
                              </IconButton>
                              <IconButton onClick={() => handleDeleteFilter(filterName)}>
                                <DeleteIcon />
                              </IconButton>
                            </div>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseManageFilters}>Cancel</Button>
          <Button onClick={handleSaveFilterChanges} color="primary">
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

JobListingsPage.propTypes = {
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
};

export default JobListingsPage;