import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  TextField,
  CircularProgress,
  Snackbar,
  useTheme,
} from '@mui/material';
import {
  Person as PersonIcon,
  Security as SecurityIcon,
  Work as WorkIcon,
} from '@mui/icons-material';
import config from './config';

const AccountsPage = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeSection, setActiveSection] = useState('personal');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const theme = useTheme();

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    try {
      const response = await fetch(`${config.API_URL}/users/me`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch user data');
      }

      const userData = await response.json();
      setUser(userData);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${config.API_URL}/users/me`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify(user)
      });

      if (!response.ok) {
        throw new Error('Failed to update user data');
      }

      showSnackbar('User information updated successfully');
    } catch (err) {
      showSnackbar('Error updating user information');
      console.error('Error updating user data:', err);
    }
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Typography variant="h6" color="error">
        {error}
      </Typography>
    );
  }

  const renderContent = () => {
    switch (activeSection) {
      case 'personal':
        return (
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              label="First Name"
              name="first_name"
              value={user.first_name || ''}
              InputProps={{
                readOnly: true,
              }}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Last Name"
              name="last_name"
              value={user.last_name || ''}
              InputProps={{
                readOnly: true,
              }}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Email"
              name="email"
              value={user.email || ''}
              InputProps={{
                readOnly: true,
              }}
              margin="normal"
            />
            {/* <TextField
              fullWidth
              label="Phone Number"
              name="phone_number"
              value={user.phone_number || ''}
              onChange={handleInputChange}
              margin="normal"
            /> */}
            {/* <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{ mt: 2 }}
            >
              Save Changes
            </Button> */}
          </form>
        );
      case 'security':
        return (
          <Typography variant="body1">
            Security settings will be implemented here.
          </Typography>
        );
      case 'professional':
        return (
          <Typography variant="body1">
            Professional information settings will be implemented here.
          </Typography>
        );
      default:
        return null;
    }
  };

  return (
    <Box sx={{ display: 'flex', height: '100vh', gap: 3, p: 3 }}>
      {/* Sidebar */}
      <Paper 
        elevation={1}
        sx={{ 
          width: 250,
          minWidth: 250,
          borderRadius: 2,
          overflow: 'hidden',
        }}
      >
        <List>
          {[
            { id: 'personal', label: 'Personal Information', icon: <PersonIcon /> },
            { id: 'security', label: 'Security', icon: <SecurityIcon /> },
            { id: 'professional', label: 'Professional Info', icon: <WorkIcon /> },
          ].map((item) => (
            <ListItem 
              button 
              key={item.id}
              selected={activeSection === item.id}
              onClick={() => setActiveSection(item.id)}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.label} />
            </ListItem>
          ))}
        </List>
      </Paper>

      {/* Main content */}
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', height: '100%', overflow: 'hidden' }}>
        <Paper 
          elevation={1} 
          sx={{ 
            p: 3, 
            borderRadius: 2, 
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
          }}
        >
          <Typography variant="h4" sx={{ mb: 3, color: theme.palette.primary.main }}>
            {activeSection === 'personal' && 'Personal Information'}
            {activeSection === 'security' && 'Security Settings'}
            {activeSection === 'professional' && 'Professional Information'}
          </Typography>
          <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
            {renderContent()}
          </Box>
        </Paper>
      </Box>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
    </Box>
  );
};

export default AccountsPage;